import React from 'react';
import {useIntl, FormattedMessage } from "gatsby-plugin-intl";
import SEO from "../components/seo";
import Layout from '../components/Layout';

// assets
    import Image404 from '../images/misc/404.jpg';

const Error404 = (props) => {

    const intl = useIntl();

    return (

        <Layout location={props.uri}>
            <div className="page__container-inner">

                <SEO 
                    title={intl.formatMessage({ id: "title.404" })}
                    lang={intl.locale}
                />
   
                <div className="page__sections-container">
                    <div className="page__section page__section--first page-not-found">

                        <img className="page-not-found__image" src={Image404} alt="Page not found" />
                        <h1 className="page-not-found__title"><FormattedMessage id="404.text" /></h1> 
                    </div>
                </div>

            </div>
        </Layout>
       
    );

}

export default Error404;

